<template>
  <div class='content'>
    <app-cells position="start">
      <router-link
        :to="{ name: 'my-apps' }"
        class='btn btn--link'
      >
        <svg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path fill-rule='evenodd' clip-rule='evenodd'
                d='M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z'
                fill='#2B93E7'/>
          <path fill-rule='evenodd' clip-rule='evenodd'
                d='M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z'
                fill='#2B93E7'/>
        </svg>
      </router-link>
      <h1 class='title title--big title--theme'>Редактирование заявки на мероприятие</h1>
    </app-cells>

    <form @submit.prevent="onSendForm">
      <app-grid>
        <template #item-1>
          <template v-for='(item, index) in form_data'>
            <component :is='item.type' v-model='form_data[index].value' :data='item' :key="index"></component>
          </template>
        </template>
        <template #item-2>
          <div class="app-list app-list--single app-list--sticky">
            <div
              type="button"
              class="app-list__item app-list__item--no-click"
            >
              <div @click="goToEvent" class="app-list__title app-list__title--click">{{ app.event_name }}</div>
              <div class="app-list__subt">{{ app.shift_name }}</div>
              <div class="app-list__text">
                <span>Статус заявки: </span> <span class="app-list__status">{{ app.status_name }}</span>
              </div>
              <div v-if="app.reject_comment && app.status === 'rejected'" class="app-list__text">
                <span>Комментарий:</span> <span>{{ app.reject_comment }}</span>
              </div>
              <app-button
                @click="onAppDelete"
                v-if="app.status === 'rejected' || app.status === 'pending'"
                size="small"
                theme="error"
                type="button"
              >Удалить</app-button>
            </div>
          </div>
        </template>
      </app-grid>
      <app-cells>
        <app-button ref="submit" :disabled="app.status !== 'rejected' && app.status !== 'rework'">Сохранить</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import bInput from '@/components/builder/Input'
import bEmail from '@/components/builder/Email'
import bSelect from '@/components/builder/Select'
import bRadio from '@/components/builder/Radio'
import bCheckbox from '@/components/builder/Checkbox'
import bDateTime from '@/components/builder/DateTime'
import bDate from '@/components/builder/Date'
import bNumber from '@/components/builder/Number'
import bTextarea from '@/components/builder/Textarea'
import bAddress from '@/components/builder/Address'
import bUploader from '@/components/builder/Uploader'
import bTitle from '@/components/builder/Title'
import bParagraph from '@/components/builder/Paragraph'
import bPhone from '@/components/builder/Phone'
import bOrg from '@/components/builder/Org'
import bLink from '@/components/builder/Link'

export default {
  name: 'MyAppEdit',
  components: {
    bInput, bSelect, bRadio, bCheckbox, bDate, bDateTime, bNumber, bTextarea, bAddress, bUploader, bTitle, bParagraph, bPhone, bEmail, bOrg, bLink
  },
  data() {
    return {
      app: {},
      form_data: [],
    }
  },
  mounted() {
    this.$store.dispatch('events/applications/GET_MY_APP', this.$route.params.id)
      .then(response => {
        this.app = response.data
        this.form_data = response.data.form_result
      })
  },
  methods: {
    goToEvent() {
      this.$router.push({ name: 'event-detail', params: { id: this.app.event_id } })
    },
    onSendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('events/applications/PATCH_MY_APP',
        { my_form: { form_result: this.form_data }, id: this.app.id, shift_id: this.app.shift_id })
        .then(() => {
          this.$refs.submit.preload = false
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Заявка успешно сохранена'
          })
          this.$router.push({ name: 'my-apps' })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    onAppDelete() {
      this.$store.dispatch('events/applications/DELETE_MY_APP', this.$route.params.id)
        .then(() => {
          this.$router.push({ name: 'my-apps' })
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Заявка успешно удалена'
          })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                text: error.response.data[key][0]
              })
            }
          }
        })
    }
  }
}
</script>
